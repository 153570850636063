import React from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import "./SectionThree.scss"
import newProduct from "../../../../../images/mobile/solutions/newProduct.svg"
import enchancement from "../../../../../images/mobile/solutions/enchancement.svg"
import validate from "../../../../../images/mobile/solutions/validate.svg"

function SectionThree() {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  return (
    <div id="datascience-section-three">
      <div id="container">
        <div className="header">
          <h1>Soluções para organizações que precisam de:</h1>
        </div>
        <div className="solutions-column">
          <section
            id="newProduct"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset="0"
          >
            <div className="solutions">
              <img
                src={validate}
                alt="Uma mão tocando em botão que aciona uma lâmpada."
              />

              <p>Otimizar tomadas de decisão utilizando dados</p>
            </div>
          </section>

          <section
            id="enchancement"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-offset="0"
          >
            <div className="solutions">
              <img
                src={newProduct}
                alt="Um ponteiro de mouse na tela de um computador."
              />

              <p>
                Expandir o negócio tanto em termos de crescimento quanto de
                localização
              </p>
            </div>
          </section>

          <section
            id="validate"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-offset="0"
          >
            <div className="solutions">
              <img
                src={enchancement}
                alt="Uma pessoa de cabelos compridos ao lado de gráficos estatístico."
              />
              <p>Obter insights para predição de resultados</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default SectionThree

import React, { useState, useEffect } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import AOS from "aos"
import "aos/dist/aos.css"

import ImpactCases from "../../../../ImpactCases/ImpactCases"
import PinnedMenu from "../../../../../utils/PinnedMenu"

import carrefour from "../../../../../images/enterprises-colorized/carrefour.svg"
import leroy_merlin from "../../../../../images/enterprises-colorized/leroy_merlin_serialized.svg"
import sebrae from "../../../../../images/enterprises-colorized/sebrae.svg"
import neoron from "../../../../../images/enterprises-colorized/neoron.svg"

import "./SectionTwo.scss"
import "../../../../../utils/PinnedMenu/styles.scss"

const SectionTwo = () => {
  const [expandedDesignSprint, setExpandedDesignSprint] = useState(false)
  const [expandedDigitalProduct, setExpandedDigitalProduct] = useState(false)
  const [active, setActive] = useState("design-sprint")

  const [fixedDesignSprint, setFixedDesignSprint] = useState(false)
  const [fixedDigitalProduct, setFixedDigitalProduct] = useState(false)

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })

  useEffect(() => {
    function fixedMenusInTop() {
      const designSprintMenu = new PinnedMenu(
        "design-sprint-menu",
        "design-sprint-content"
      )
      const digitalProductMenu = new PinnedMenu(
        "digital-product-menu",
        "digital-product-content"
      )
      const menuDesktop = new PinnedMenu("collapse-and-slider-menu", "sliders")

      setFixedDesignSprint(
        designSprintMenu.shouldItBePinned(expandedDesignSprint)
      )
      if (designSprintMenu.shouldItBePinned(expandedDesignSprint))
        designSprintMenu.pin()
      else designSprintMenu.unpin()

      setFixedDigitalProduct(
        digitalProductMenu.shouldItBePinned(expandedDigitalProduct)
      )
      if (digitalProductMenu.shouldItBePinned(expandedDigitalProduct))
        digitalProductMenu.pin()
      else digitalProductMenu.unpin()

      if (menuDesktop.shouldItBePinned(true, true)) menuDesktop.pin(true)
      else menuDesktop.unpin(true)
    }
    window.addEventListener("scroll", fixedMenusInTop)
    fixedMenusInTop()
  }, [expandedDesignSprint, expandedDigitalProduct])

  return (
    <div id="datascience-section-two">
      <div id="content" />
      <div id="phases-tech"></div>
      <ul id="collapse-and-slider-menu" className="collapse-menu">
        <li className="collapse-menu-item">
          <button
            id="design-sprint-menu"
            className={`collapse-menu-header  ${
              active === "design-sprint" ? "active" : ""
            } ${fixedDesignSprint ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDesignSprint(!expandedDesignSprint)
              setActive("design-sprint")
            }}
          >
            <h2 className="collapse-menu-title" id="design-sprint">
              PROVA DE CONCEITO (PoC)
            </h2>
            {!expandedDesignSprint ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="design-sprint-content"
            className={`collapse-menu-content 
                  ${expandedDesignSprint ? "expanded" : "contracted"}`}
          >
            <h3>Validação da solução tecnológica</h3>
            <p>
              Experimentamos o desenvolvimento de sistemas de modo a garantir a
              viabilidade técnica da solução, garantindo que o que foi
              planejamento e criado se prove viável quando abordado no
              desenvolvimento.
            </p>

            <p>
              <strong>
                Será que você possui dados o suficiente? É possível desenvolver
                a ideia com as ferramentas que temos em mãos hoje?
              </strong>
              Essas são algumas das perguntas que respondemos com a pOC.
            </p>
            <ImpactCases
              cases={[
                {
                  image: carrefour,
                  enterprise: "Carrefour",
                  title: "GeoPop Carrefour",
                  text:
                    "A gigante do varejo tinha a necessidade de abrir novas lojas do Carrefour Express, porém não visualizava onde estariam. A empresa conceituou um sistema que indicasse quais seriam os melhores pontos, com base em concorrência, número de habitantes e poder aquisitivo. Para entender o processo de captura desses dados e a viabilidade técnica da indicação, aplicamos uma PoC (prova de conceito).",
                },
                {
                  image: sebrae,
                  enterprise: "Sebrae",
                  title: "Sebrae Cursos",
                  text:
                    "Devido a alta oferta de cursos e o grande volume de dados, o Sebrae precisava automatizar e otimizar a recomendação de cursos com base em usuários que já haviam comprado algum produto da organização. Para entender a viabilidade técnica deste tipo de recomendação, com base na qualidade e das ferramentas disponíveis, aplicamos uma PoC.",
                },
              ]}
            />
            <div className="know-more-mobile">
              <p>Quer saber mais sobre a Prova de Conceito?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>
        </li>
        <li className="collapse-menu-item">
          <button
            id="digital-product-menu"
            className={`collapse-menu-header  ${
              active === "digital-product" ? "active" : ""
            } ${fixedDigitalProduct ? "fixed-mobile" : ""}`}
            onClick={() => {
              setExpandedDigitalProduct(!expandedDigitalProduct)
              setActive("digital-product")
            }}
          >
            <h2 className="collapse-menu-title" id="digital-product">
              ADVANCED ANALYTICS
            </h2>

            {!expandedDigitalProduct ? (
              <FiPlus size={30} />
            ) : (
              <FiMinus size={30} />
            )}
          </button>
          <div
            id="digital-product-content"
            className={`collapse-menu-content 
                  ${expandedDigitalProduct ? "expanded" : "contracted"}`}
          >
            <h3>Insights e soluções data-driven</h3>
            <p>
              Examinamos dados utilizando técnicas sofisticadas de ciência de
              dados a partir da metodologia CRISP-DM para descobrir insights
              profundos, fazer predições e gerar recomendações.
            </p>
            <ImpactCases
              cases={[
                {
                  enterprise: `Leroy Merlin`,
                  title: `Leroy Merlin Express`,
                  text:
                    "A Leroy Merlin havia desenhado um novo formato de lojas chamado “Leroy Merlin Express”. Contudo, o estudo de expansão do formato ainda não contava com um modelo (algoritmo) de recomendação que pudesse dar suporte na análise simultânea de diversos pontos do estado de São Paulo. Usamos o Advanced Analytics para desenvolver um algoritmo de predição do faturamento express para cada setor censitário do estado de São Paulo e renderizamos os resultados em mapa HTML para fácil visualização e navegação da área de expansão. ",
                },

                {
                  image: neoron,
                  enterprise: "Neoron",
                  title: "Plataforma Neoron",
                  text:
                    "A NEORON nasceu com o objetivo de melhorar o atendimento ao cliente e obter dados estratégicos, por meio de uma plataforma simples e intuitiva que atua com chatbots. Desenvolvemos a plataforma utilizando inteligência artificial conversacional e machine learning - com uma interface já validado em um Design Sprint.",
                },
              ]}
            />
            <div className="know-more-mobile">
              <p>Quer saber mais sobre o Advanced Analytics?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <ul
        id="sliders"
        className="slider-menu"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <li
          id="design-sprint-lg"
          className={`slider-menu-page ${
            active === "design-sprint" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <h1 className="title">Validação da solução tecnológica</h1>
            <p className="text">
              Experimentamos o desenvolvimento de sistemas de modo a garantir a
              viabilidade técnica da solução, garantindo que o que foi
              planejamento e criado se prove viável quando abordado no
              desenvolvimento..
              <br />
              <br />
              <strong>
                Será que você possui dados o suficiente? É possível desenvolver
                a ideia com as ferramentas que temos em mãos hoje?{" "}
              </strong>
              Essas são algumas das perguntas que respondemos com a PoC.
            </p>
            <div className="know-more">
              <p>Quer saber mais sobre a Prova de Conceito?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>

          <div className="carousel-container">
            <ImpactCases
              cases={[
                {
                  image: carrefour,
                  enterprise: "Carrefour",
                  title: "GeoPop Carrefour",
                  text:
                    "A gigante do varejo tinha a necessidade de abrir novas lojas do Carrefour Express, porém não visualizava onde estariam. A empresa conceituou um sistema que indicasse quais seriam os melhores pontos, com base em concorrência, número de habitantes e poder aquisitivo. Para entender o processo de captura desses dados e a viabilidade técnica da indicação, aplicamos uma PoC (prova de conceito).",
                },
                {
                  image: sebrae,
                  enterprise: "Sebrae",
                  title: "Sebrae Cursos",
                  text:
                    "Devido a alta oferta de cursos e o grande volume de dados, o Sebrae precisava automatizar e otimizar a recomendação de cursos com base em usuários que já haviam comprado algum produto da organização. Para entender a viabilidade técnica deste tipo de recomendação, com base na qualidade e das ferramentas disponíveis, aplicamos uma PoC.",
                },
              ]}
            />
          </div>
        </li>
        <li
          id="digital-product-lg"
          className={`slider-menu-page ${
            active === "digital-product" ? "active" : ""
          }`}
        >
          <div className="content-container">
            <h1 className="title">Insights e soluções data-driven</h1>
            <p className="text">
              Examinamos dados utilizando técnicas sofisticadas de ciência de
              dados a partir da metodologia CRISP-DM para descobrir insights
              profundos, fazer predições e gerar recomendações.
            </p>
            <div className="know-more">
              <p>Quer saber mais sobre o Advanced Analytics?</p>
              <a href="/contact/#">
                <button>Fale Conosco</button>
              </a>
            </div>
          </div>

          <div className="carousel-container">
            <ImpactCases
              cases={[
                {
                  image: leroy_merlin,
                  enterprise: "Leroy Merlin",
                  title: `Leroy Merlin \nExpress`,
                  text:
                    "A Leroy Merlin havia desenhado um novo formato de lojas chamado “Leroy Merlin Express”. Contudo, o estudo de expansão do formato ainda não contava com um modelo (algoritmo) de recomendação que pudesse dar suporte na análise simultânea de diversos pontos do estado de São Paulo. Usamos o Advanced Analytics para desenvolver um algoritmo de predição do faturamento express para cada setor censitário do estado de São Paulo e renderizamos os resultados em mapa HTML para fácil visualização e navegação da área de expansão. ",
                },
              ]}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SectionTwo

export function getElementHeight(element) {
  return element.getBoundingClientRect().height
}

export function getElementTop(element) {
  return element.getBoundingClientRect().top
}

export function getElementBottom(element) {
  return element.getBoundingClientRect().bottom
}

export function elementIsOnScreen(element, offset = 0, offsetBottom = 0) {
  return (
    getElementBottom(element) > offset &&
    getElementTop(element) < window.innerHeight - offsetBottom
  )
}

import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../../utils/RDStationConnection"

import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

import SectionOne from "../../components/Solutions/Fabtech/DataScience/SectionOne/SectionOne"
import SectionTwo from "../../components/Solutions/Fabtech/DataScience/SectionTwo/SectionTwo"
import SectionThree from "../../components/Solutions/Fabtech/DataScience/SectionThree/SectionThree"
import SectionFour from "../../components/Solutions/Fabtech/DataScience/SectionFour/SectionFour"
import SectionFive from "../../components/Solutions/Fabtech/DataScience/SectionFive/SectionFive"
import SectionSix from "../../components/Solutions/Fabtech/DataScience/SectionSix/SectionSix"

export default function Fabtech() {
  useEffect(() => {
    RDStationConection.init()
    document.getElementById("label").style.backgroundColor = "#56b265"
  })
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAB tech - Ciência de Dados | FABWORK</title>
        <link rel="canonical" href="https://fab.work/fabtech/" />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <Footer />
    </div>
  )
}

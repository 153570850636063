import React from "react"
import Slider from "react-slick"

import "./ImpactCases.scss"

const ImpactCases = ({ className, style, cases }) => {
  let settings = {
    dots: true,
    // dotsClass: "fabwork-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    // autoplaySpeed: 15000,
  }

  return (
    <div id="impact-cases" className={className} style={style}>
      <div className="impact-case-label">
        <p>Case de impacto</p>
      </div>
      <Slider {...settings}>
        {cases.map((Case, index) => (
          <div className="item" key={index} id={index}>
            {Case.image ? (
              <img
                id={Case.enterprise.split(" ")[0]}
                src={Case.image}
                alt={`Logo da empresa ${Case.enterprise}`}
              />
            ) : (
              <></>
            )}
            <h1>{Case.title}</h1>
            <p>{Case.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ImpactCases

import {
  getElementTop,
  elementIsOnScreen,
  getElementHeight,
} from "../ElementAtributes"

class PinnedMenu {
  NAVBAR_DESKTOP_HEIGHT = 85 //+ 37
  NAVBAR_MOBILE_HEIGHT = 80 //+ 37

  constructor(menuID, contentID) {
    this.menu = document.getElementById(menuID)
    this.content = document.getElementById(contentID)
  }

  shouldItBePinned(controlVariable = true, isDesktop = false) {
    /**
     * controlVariable: Boolean
     * isDesktop: Boolean
     */
    if (isDesktop) {
      return this.shouldItBePinnedDesktop()
    }

    return this.shouldItBePinnedMobile(controlVariable)
  }

  shouldItBePinnedDesktop() {
    return (
      getElementTop(this.menu) <= this.NAVBAR_DESKTOP_HEIGHT &&
      elementIsOnScreen(this.content, this.NAVBAR_DESKTOP_HEIGHT) &&
      getElementTop(this.content) <=
        this.NAVBAR_DESKTOP_HEIGHT + getElementHeight(this.menu) * 2
    )
  }

  shouldItBePinnedMobile(controlVariable) {
    /**
     * controlVariable: Boolean
     */
    const menuHeight = getElementHeight(this.menu)

    return (
      getElementTop(this.menu) <= this.NAVBAR_MOBILE_HEIGHT &&
      elementIsOnScreen(this.content, this.NAVBAR_MOBILE_HEIGHT + menuHeight) &&
      controlVariable
    )
  }

  pin(isDesktop = false) {
    const height = getElementHeight(this.menu)

    if (isDesktop) {
      this.menu.classList.add("fixed-desktop")
      this.content.style.marginTop = `${height + 32.5}px`
    }

    this.menu.classList.add("fixed-mobile")
    this.content.style.marginTop = `${height + 15}px`
  }

  unpin(isDesktop = false) {
    if (isDesktop) {
      this.menu.classList.remove("fixed-desktop")
      this.content.style.marginTop = "65px"
    }

    this.menu.classList.remove("fixed-mobile")
    this.content.style.marginTop = "15px"
  }
}

export default PinnedMenu
